@tailwind base;

@layer base {
  h1 {
    @apply text-h1 leading-[44px];
  }
  h2 {
    @apply text-h2 leading-[40px];
  }
  h3 {
    @apply text-h3 leading-[32px];
  }
  p {
    @apply text-p1;
  }
  button {
    @apply text-btn;
  }
}

@tailwind components;
@tailwind utilities;

@keyframes slideIn {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(100%);
  }
}

.hover-effect {
  @apply transition-transform transform hover:-translate-y-1;
}
